import { FC } from 'react'
import React from 'react'
import type { Money } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'

import {
  CurrencyInput,
  Select,
  Input,
  InputType,
  Button,
  ButtonGroup,
  Checkbox,
  GenericField,
} from '@extend/zen'
import styles from './add-expense-form.module.css'
import { useAddExpenseForm } from '../../../hooks/use-add-expense-form'
import { RateCard } from '../../../queries/servicers'

export const AddExpenseForm: FC<{
  closeForm: () => void
  currencyCode?: Money['currencyCode']
  serviceOrderId: string
  rateCard?: RateCard
}> = ({ closeForm, currencyCode = 'USD', serviceOrderId, rateCard }) => {
  const {
    expenseType,
    description,
    setDescription,
    partsNumber,
    replacementProduct,
    isGoodwill,
    setIsGoodwill,
    rate,
    setRate,
    wholesaleCost,
    setWholesaleCost,
    quantity,
    setQuantity,
    shouldShowReplacementProductInput,
    shouldShowPartsNumberInput,
    totalCost,
    isLoading,
    handleCreateExpenseSubmit,
    handleOnClickCancel,
    handleSelectExpenseType,
    updatePartsNumberAndMetaData,
    updateReplacementProductAndMetaData,
    isSubmitDisabled,
    descriptionHasError,
    partsNumberHasError,
    replacementProductHasError,
    rateError,
    quantityError,
    setDescriptionHasBeenBlurred,
    setPartsNumberHasBeenBlurred,
    setReplacementProductHasBeenBlurred,
    setRateHasBeenBlurred,
    setQuantityHasBeenBlurred,
    currentRate,
    filteredExpenseTypes,
    descriptionOptions,
  } = useAddExpenseForm({ closeForm, serviceOrderId, currencyCode, rateCard })

  return (
    <section className={styles.container}>
      <h2>Add Expense</h2>
      <form id="addExpenseForm" onSubmit={handleCreateExpenseSubmit}>
        <div className={styles.grid}>
          <Select
            label="Expense Type"
            id="expenseType"
            placeholder="Select"
            value={expenseType}
            onChange={handleSelectExpenseType}
          >
            {filteredExpenseTypes.map(({ value, display }) => (
              <option value={value} key={value}>
                {display}
              </option>
            ))}
          </Select>
          <div className={styles['two-column']}>
            {rateCard ? (
              <Select
                label="Description"
                id="description"
                placeholder="Select"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                isError={descriptionHasError}
                errorFeedback="Required"
                onBlur={() => setDescriptionHasBeenBlurred(true)}
              >
                {descriptionOptions.map(({ value, display }) => (
                  <option value={value} key={value}>
                    {display}
                  </option>
                ))}
              </Select>
            ) : (
              <Input
                label="Description"
                id="description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                isError={descriptionHasError}
                errorFeedback="Required"
                onBlur={() => setDescriptionHasBeenBlurred(true)}
              />
            )}
          </div>
          <GenericField hasLabelSpacing>
            <Checkbox
              checked={isGoodwill}
              label="Goodwill"
              onChange={() => setIsGoodwill(!isGoodwill)}
            />
          </GenericField>
          {shouldShowPartsNumberInput && (
            <Input
              id="partsNumber"
              label="Parts Number"
              value={partsNumber}
              onChange={updatePartsNumberAndMetaData}
              isError={partsNumberHasError}
              errorFeedback="Required"
              onBlur={() => setPartsNumberHasBeenBlurred(true)}
            />
          )}
          {shouldShowReplacementProductInput && (
            <Input
              id="replacementProduct"
              label="Replacement Product"
              value={replacementProduct}
              onChange={updateReplacementProductAndMetaData}
              isError={replacementProductHasError}
              errorFeedback="Required"
              onBlur={() => setReplacementProductHasBeenBlurred(true)}
            />
          )}
        </div>
        <div className={styles.grid}>
          <Select label="Incurred By" id="incurredBy" value="servicer" isDisabled>
            <option value="servicer">Servicer</option>
          </Select>
          <CurrencyInput
            currency={currencyCode}
            id="rate"
            label="Rate"
            currencyDisplay="code"
            onChange={(event) => setRate(event.target.value)}
            value={rate}
            isError={Boolean(rateError)}
            errorFeedback={rateError}
            isDisabled={currentRate?.rate !== undefined}
            onBlur={() => setRateHasBeenBlurred(true)}
            subtext={
              currentRate && !currentRate.rate && currentRate.costHigh
                ? `Rate Card max ${currency.format(currentRate.costHigh.amount)}`
                : undefined
            }
          />
          <CurrencyInput
            currency={currencyCode}
            id="wholesaleCost"
            label="Wholesale Cost (Optional)"
            currencyDisplay="code"
            onChange={(event) => setWholesaleCost(event.target.value)}
            value={wholesaleCost}
            errorFeedback="Required"
            isDisabled={currentRate?.rate !== undefined}
            onBlur={() => setRateHasBeenBlurred(true)}
          />
          <Input
            type={InputType.number}
            id="quantity"
            label="Quantity"
            step={0.25}
            onChange={(event) => setQuantity(event.currentTarget.value)}
            value={quantity}
            min={0}
            placeholder="0"
            subtext={
              currentRate && currentRate.qtyHigh
                ? `Rate Card max ${currentRate.qtyHigh}`
                : undefined
            }
            isError={Boolean(quantityError)}
            errorFeedback={quantityError}
            onBlur={() => setQuantityHasBeenBlurred(true)}
          />
          <GenericField label="Total">
            {currency.formatWithoutCurrencySymbol(totalCost, currencyCode, true)}
          </GenericField>
        </div>
        <ButtonGroup justify="end">
          <Button
            emphasis="medium"
            ariaLabel="Cancel"
            text="Cancel"
            onClick={handleOnClickCancel}
          />
          <Button
            type="submit"
            ariaLabel="Add Expense"
            text="Add Expense"
            isProcessing={isLoading}
            isDisabled={isSubmitDisabled}
          />
        </ButtonGroup>
      </form>
    </section>
  )
}
