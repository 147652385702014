import type { Dispatch, SetStateAction, SyntheticEvent, ChangeEvent } from 'react'
import { useMemo, useState } from 'react'
import type { ExpenseType, Money } from '@helloextend/extend-api-client'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useCreateServiceOrderExpenseMutation } from '../queries/service-orders'
import { RateCard } from '../queries/servicers'
import { currency } from '@extend/client-helpers'

export const PARTS = 'parts'
export const REPLACEMENT = 'replacement'
export const REFURBISHED_REPLACEMENT = 'refurbished_replacement'

export const expenseTypeMapping = [
  { value: 'diagnosis_fee', display: 'Diagnostic Fee' },
  { value: 'labor', display: 'Labor - Hourly Rate' },
  { value: 'parts', display: 'Parts' },
  { value: 'refurbished_replacement', display: 'Refurbished Replacement' },
  { value: 'replacement', display: 'Replacement' },
  { value: 'taxes', display: 'Taxes' },
  { value: 'trip_charges', display: 'Trip Charges' },
  { value: 'cleaning_kit', display: 'Cleaning Kit' },
  { value: 'shipping', display: 'Shipping & Handling' },
]

export const useAddExpenseForm = ({
  closeForm,
  serviceOrderId,
  currencyCode,
  rateCard,
}: {
  closeForm: () => void
  serviceOrderId: string
  currencyCode: Money['currencyCode']
  rateCard?: RateCard
}): {
  expenseType: ExpenseType
  description: string
  setDescription: Dispatch<SetStateAction<string>>
  partsNumber: string
  replacementProduct: string
  isGoodwill: boolean
  setIsGoodwill: Dispatch<SetStateAction<boolean>>
  rate: string
  setRate: Dispatch<SetStateAction<string>>
  wholesaleCost: string
  setWholesaleCost: Dispatch<SetStateAction<string>>
  quantity: string
  setQuantity: Dispatch<SetStateAction<string>>
  shouldShowReplacementProductInput: boolean
  shouldShowPartsNumberInput: boolean
  totalCost: number
  isLoading: boolean
  handleCreateExpenseSubmit: (event: SyntheticEvent) => Promise<void>
  handleOnClickCancel: () => void
  handleSelectExpenseType: (event: ChangeEvent<HTMLSelectElement>) => void
  updatePartsNumberAndMetaData: (event: ChangeEvent<HTMLInputElement>) => void
  updateReplacementProductAndMetaData: (event: ChangeEvent<HTMLInputElement>) => void
  isSubmitDisabled: boolean
  descriptionHasError: boolean
  partsNumberHasError: boolean
  replacementProductHasError: boolean
  rateError?: string
  quantityError?: string
  setDescriptionHasBeenBlurred: Dispatch<SetStateAction<boolean>>
  setPartsNumberHasBeenBlurred: Dispatch<SetStateAction<boolean>>
  setReplacementProductHasBeenBlurred: Dispatch<SetStateAction<boolean>>
  setRateHasBeenBlurred: Dispatch<SetStateAction<boolean>>
  setQuantityHasBeenBlurred: Dispatch<SetStateAction<boolean>>
  currentRate?: RateCard['rates'][0]
  descriptionOptions: { value: string; display: string }[]
  filteredExpenseTypes: { value: string; display: string }[]
} => {
  const { mutateAsync, isLoading } = useCreateServiceOrderExpenseMutation()
  const { toast } = useToaster()

  const [expenseType, setExpenseType] = useState('' as ExpenseType)
  const [description, setDescription] = useState('')
  const [partsNumber, setPartsNumber] = useState('')
  const [replacementProduct, setReplacementProduct] = useState('')
  const [isGoodwill, setIsGoodwill] = useState(false)
  const [rate, setRate] = useState('')
  const [wholesaleCost, setWholesaleCost] = useState('')
  const [quantity, setQuantity] = useState('')
  const [metaData, setMetaData] = useState({})

  // Validation Utilities ===========================================
  const [descriptionHasBeenBlurred, setDescriptionHasBeenBlurred] = useState(false)
  const [partsNumberHasBeenBlurred, setPartsNumberHasBeenBlurred] = useState(false)
  const [replacementProductHasBeenBlurred, setReplacementProductHasBeenBlurred] = useState(false)
  const [rateHasBeenBlurred, setRateHasBeenBlurred] = useState(false)
  const [quantityHasBeenBlurred, setQuantityHasBeenBlurred] = useState(false)

  const currentRate = useMemo((): RateCard['rates'][0] | undefined => {
    if (!rateCard || rateCard.rates.length === 0) return

    const newRate = rateCard.rates.find(
      (rate) =>
        expenseType &&
        rate.type === expenseType &&
        description &&
        rate.description.toLowerCase() === description.toLowerCase(),
    )
    if (newRate?.rate !== undefined) {
      setRate(newRate.rate.amount.toString())
      setWholesaleCost('')
    }
    return newRate
  }, [expenseType, description])

  const descriptionHasError = useMemo(() => {
    return (
      Boolean(expenseType) &&
      !description &&
      expenseType !== 'cleaning_kit' &&
      descriptionHasBeenBlurred
    )
  }, [description, descriptionHasBeenBlurred, expenseType])

  const partsNumberHasError = useMemo(() => {
    return (
      Boolean(expenseType) && expenseType === 'parts' && !partsNumber && partsNumberHasBeenBlurred
    )
  }, [expenseType, partsNumber, partsNumberHasBeenBlurred])

  const replacementProductHasError = useMemo(() => {
    return (
      Boolean(expenseType) &&
      (expenseType === 'replacement' || expenseType === 'refurbished_replacement') &&
      !replacementProduct &&
      replacementProductHasBeenBlurred
    )
  }, [expenseType, replacementProduct, replacementProductHasBeenBlurred])

  const rateError = useMemo(() => {
    if (Boolean(expenseType) && !rate && rateHasBeenBlurred) return 'Required'
    if (currentRate) {
      if (parseInt(rate) < currentRate.costLow.amount) {
        return `Enter minimum of ${currency.format(currentRate.costLow.amount)}`
      } else if (currentRate.costHigh && parseInt(rate) > currentRate.costHigh.amount) {
        return `Enter maximum of ${currency.format(currentRate.costHigh.amount)}`
      }
    }
    return undefined
  }, [expenseType, rate, rateHasBeenBlurred])

  const quantityError = useMemo(() => {
    if ((Boolean(expenseType) && !quantity && quantityHasBeenBlurred) || quantity === '0')
      return 'Required'
    if (currentRate) {
      if (parseFloat(quantity) < currentRate.qtyLow) {
        return `Enter minimum of ${currentRate.qtyLow}`
      } else if (currentRate.qtyHigh && parseFloat(quantity) > currentRate.qtyHigh) {
        return `Enter maximum of ${currentRate.qtyHigh}`
      }
    }
    return undefined
  }, [expenseType, quantity, quantityHasBeenBlurred, currentRate])

  const isSubmitDisabled = useMemo(() => {
    const standardFormInputsShouldDisableSubmit =
      descriptionHasError ||
      !description ||
      Boolean(rateError) ||
      !rate ||
      Boolean(quantityError) ||
      !quantity
    if (!expenseType) {
      return true
    }
    if (expenseType === 'parts') {
      return standardFormInputsShouldDisableSubmit || partsNumberHasError || !partsNumber
    }

    if (expenseType === 'replacement' || expenseType === 'refurbished_replacement') {
      return (
        standardFormInputsShouldDisableSubmit || replacementProductHasError || !replacementProduct
      )
    }
    return standardFormInputsShouldDisableSubmit
  }, [
    description,
    descriptionHasError,
    expenseType,
    partsNumber,
    partsNumberHasError,
    quantity,
    quantityError,
    rate,
    rateError,
    replacementProduct,
    replacementProductHasError,
  ])

  // End Validation Utilities ====================

  const clearForm = (): void => {
    setExpenseType('' as ExpenseType)
    setDescription('')
    setPartsNumber('')
    setReplacementProduct('')
    setIsGoodwill(false)
    setRate('')
    setQuantity('')
    setMetaData({})
    setDescriptionHasBeenBlurred(false)
    setPartsNumberHasBeenBlurred(false)
    setReplacementProductHasBeenBlurred(false)
    setRateHasBeenBlurred(false)
    setQuantityHasBeenBlurred(false)
  }

  const updatePartsNumberAndMetaData = (event: ChangeEvent<HTMLInputElement>): void => {
    setPartsNumber(event.target.value)
    setMetaData({ partNumber: event.target.value })
  }

  const updateReplacementProductAndMetaData = (event: ChangeEvent<HTMLInputElement>): void => {
    setReplacementProduct(event.target.value)
    setMetaData({ productName: event.target.value })
  }

  const shouldShowReplacementProductInput =
    expenseType === REPLACEMENT || expenseType === REFURBISHED_REPLACEMENT

  const shouldShowPartsNumberInput = expenseType === PARTS

  const numericizedQuantity = Number(quantity)
  const numericizedRate = parseInt(rate, 10)
  const numericizedWholesaleCost = wholesaleCost ? parseInt(wholesaleCost, 10) : 0
  const totalCost = multiplyCostAndQuantity(numericizedRate, numericizedQuantity)

  const handleOnClickCancel = (): void => {
    closeForm()
    clearForm()
  }

  const setMetaDataByExpenseType = (expenseTypeValue: string): void => {
    if (expenseTypeValue === PARTS) {
      return setMetaData({ partNumber: '' })
    }
    if (expenseTypeValue === REPLACEMENT || expenseTypeValue === REFURBISHED_REPLACEMENT) {
      return setMetaData({ productName: '' })
    }
    return setMetaData({})
  }

  const setDefaultRateForCleaningKitExpenseType = (expenseTypeValue: string): void => {
    if (expenseTypeValue === 'cleaning_kit') {
      return setRate('500')
    }
    return setRate('')
  }

  const handleSelectExpenseType = (event: ChangeEvent<HTMLSelectElement>): void => {
    setExpenseType(event.target.value as ExpenseType)
    setMetaDataByExpenseType(event.target.value)
    setDefaultRateForCleaningKitExpenseType(event.target.value)

    if (!event.target.value) {
      clearForm()
    }
  }

  const handleCreateExpenseSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault()
    try {
      await mutateAsync({
        serviceOrderId,
        body: {
          type: expenseType,
          cost: { amount: numericizedRate, currencyCode },
          description,
          status: 'approved', // approved is default setting
          quantity: numericizedQuantity,
          ...(wholesaleCost && {
            wholesaleCost: {
              amount: numericizedWholesaleCost,
              currencyCode,
            },
          }),
          totalCost,
          incurredBy: 'servicer',
          isGoodwill,
          metaData,
        },
      })
      toast({
        message: 'You have successfully added an expense',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      closeForm()
    } catch (err) {
      toast({
        message: 'There was an error adding an expense. Please try again',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const descriptionOptions = useMemo((): { value: string; display: string }[] => {
    if (!rateCard || !expenseType) return []
    return rateCard.rates
      .filter((rate) => rate.type === expenseType)
      .map((rate) => ({ value: rate.description, display: rate.description }))
  }, [expenseType])

  const filteredExpenseTypes = useMemo(() => {
    if (!rateCard) return expenseTypeMapping
    return expenseTypeMapping.filter((type) =>
      rateCard.rates.some((rate) => rate.type === type.value),
    )
  }, [expenseTypeMapping, expenseType])

  return {
    expenseType,
    description,
    setDescription,
    partsNumber,
    replacementProduct,
    isGoodwill,
    setIsGoodwill,
    rate,
    setRate,
    wholesaleCost,
    currentRate,
    setWholesaleCost,
    quantity,
    setQuantity,
    shouldShowReplacementProductInput,
    shouldShowPartsNumberInput,
    totalCost,
    isLoading,
    handleCreateExpenseSubmit,
    handleOnClickCancel,
    descriptionOptions,
    filteredExpenseTypes,
    handleSelectExpenseType,
    updatePartsNumberAndMetaData,
    updateReplacementProductAndMetaData,
    isSubmitDisabled,
    descriptionHasError,
    partsNumberHasError,
    replacementProductHasError,
    rateError,
    quantityError,
    setDescriptionHasBeenBlurred,
    setPartsNumberHasBeenBlurred,
    setReplacementProductHasBeenBlurred,
    setRateHasBeenBlurred,
    setQuantityHasBeenBlurred,
  }
}

const multiplyCostAndQuantity = (cost: number, quantity = 1): number => {
  // To avoid multiplying decimals, both will be multiplied by 100
  if (quantity === 1 || cost === 0) return cost
  const multipliedQuantity = quantity * 100
  const multipliedCost = cost * 100
  return Math.round((multipliedQuantity * multipliedCost) / 10000)
}
